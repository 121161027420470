export const getResponseMetadataFragment = () => `_metadata {
    axis1Sort {
        property
        order
    }
    axis2Sort {
        property
        order
    }
    limit
    cutoff
}`
