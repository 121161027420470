export const getRatiosFragment = () => `
    ratios {
      awareness
      interest
      usage
      retention
      positivity
      positivityRelative
    }
`
