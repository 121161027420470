export const getPercentilesFragment = () => `
    percentilesByFacet {
        p0
        p10
        p25
        p50
        p75
        p90
        p100
    }
`
